import React, { useEffect } from "react";
import { useLocation } from "react-router-dom"; // Gunakan useLocation dari React Router
import { Helmet } from "react-helmet-async";
import { Parallax } from "react-parallax";
import Navbar from "../layout/Navbarpf";
import Preloader from "../layout/preloader";
import Homestatic from "../section-pages/pf/homestatic-video";
import Section1 from "../section-pages/pf/section-1-center";
import Pricelist from "../section-pages/pf/pricelist-1";
import Help from "../section-pages/pf/help-center";
import Payment from "../section-pages/pf/Payment";
import Footer from "../section-pages/pf/footerrlpf";
import About from "../section-pages/pf/about";
import Listapps from "../section-pages/pf/listapps";
import { createGlobalStyle } from "styled-components";
import Announcement from "../section-pages/pf/Announcement";

const GlobalStyles = createGlobalStyle`
  
`;

const image3 = "/img/background/pf1.webp";
const image1 = "/img/background/pf3.webp";

export default function Home() {
  const location = useLocation(); // Dapatkan informasi URL dan hash
  
  useEffect(() => {
    if (typeof window !== "undefined") {
      const loader = document.getElementById("mainpreloader");
      if (loader)
        setTimeout(() => {
          loader.classList.add("fadeOut");
          loader.style.display = "none";
        }, 600);
    }
  }, []);

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]); // Akan dijalankan setiap kali hash berubah

  return (
    <>
      <Helmet>
        <link rel="icon" href="/img/icon.png" />
        <title>Primefussion | All-in-One Premium Access</title>
        <meta name="description" content="Dapatkan akses instan ke lebih dari 50 aplikasi premium seperti Netflix, Canva, ChatGPT, dan banyak lagi, hanya dengan Rp35.000 melalui PrimeFussion. Cara termurah untuk menikmati aplikasi terpopuler!" />
        <meta name="keywords" content="PrimeFussion, Aplikasi Premium, Netflix, Canva, ChatGPT, Hulu, Disney+, Coursera, Grammarly, Skillshare, Duolingo, Udemy, Streaming, Produktivitas, Langganan Murah" />
        <meta property="og:title" content="PrimeFussion - Akses Premium All-in-One, Hanya Rp35.000!" />
        <meta property="og:description" content="Nikmati akses instan ke lebih dari 50 aplikasi premium dengan PrimeFussion, mulai dari Rp35.000. Tidak perlu banyak langganan—cukup satu harga untuk semua aplikasi!" />
        <meta property="og:image" content="https://primefussion.com/img/thumbnails/ogg.jpg" />
        <meta property="og:url" content="https://primefussion.com/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://primefussion.com/img/thumbnails/ogg.jpg" />
        <meta name="twitter:title" content="PrimeFussion - Akses Premium All-in-One, Hanya Rp35.000!" />
        <meta name="twitter:description" content="Berlangganan sekarang di PrimeFussion dan dapatkan akses ke lebih dari 50 aplikasi premium, hanya dengan Rp35.000." />
      </Helmet>

      <GlobalStyles />

      <div id="mainpreloader">
        <Preloader />
      </div>

      <div className="home dark-scheme">
        <header id="header-wrap">
          <Navbar />
        </header>

        <section id="home" className="no-padding no-bottom">
          <Homestatic />
        </section>

        <section id="section1" className="no-top no-bottom tex-center">
          <Section1 />
        </section>

        <section id="about" className="no-bottom no-top">
          <About />
        </section>

        <Parallax className="" bgImage={image3} strength={150}>
          <section id="pricing" className="no-bg no-bottom no-top">
            <Pricelist />
          </section>
        </Parallax>

        <section id="payment" className="no-bottom no-top">
          <Payment />
        </section>

        <Parallax className="" bgImage={image1} strength={300}>
          <div className="de-gradient-edge-top"></div>
          <div className="de-gradient-edge-bottom"></div>
          <section id="listapps" className="no-bg no-top">
            <Listapps />
          </section>
        </Parallax>

        <section id="faq" className="no-top">
          <Help />
        </section>

        <Footer />
      </div>
      <Announcement />
    </>
  );
}
