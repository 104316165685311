import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

const Announcement = ({ delay = 5000 }) => {
  const [announcements, setAnnouncements] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isHiding, setIsHiding] = useState(false);
  const announcementRef = useRef(null); // Ref untuk mendeteksi klik di luar area

  // Fetch data pengumuman dari backend
  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/announcements`
        );
        // Filter hanya yang pinned: true
        const pinnedAnnouncements = response.data.filter(
          (announcement) => announcement.pinned
        );
        setAnnouncements(pinnedAnnouncements); // Set hanya pengumuman yang di-pin
      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    };

    fetchAnnouncements();
  }, []);

  // Open announcement automatically after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, delay); // Use the delay from props

    return () => clearTimeout(timer);
  }, [delay]);

  // Handler untuk mendeteksi klik di luar komponen
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        announcementRef.current &&
        !announcementRef.current.contains(event.target)
      ) {
        closeAnnouncement(); // Tutup pengumuman jika klik di luar area
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [announcementRef]);

  // Function to close the announcement with animation
  const closeAnnouncement = () => {
    setIsHiding(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsHiding(false);
    }, 500); // Sesuai dengan durasi animasi slideOut
  };

  // Toggle untuk buka/tutup pengumuman
  const toggleAnnouncement = () => {
    if (isOpen) {
      closeAnnouncement();
    } else {
      setIsOpen(true);
    }
  };

  // Jika tidak ada pengumuman, jangan tampilkan apapun
  if (announcements.length === 0) {
    return null;
  }

  return (
    <div className="announcement-wrapper">
      <div className="announcement-icon" onClick={toggleAnnouncement}>
        {/* Gambar SVG bulat */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="65"
          height="65"
          viewBox="0 0 65 65"
          fill="none"
        >
          <path d="M0 0L65 65H0V0Z" fill="url(#paint0_linear_118_16)" />
          <path
            d="M13.5546 30.2997C12.7035 27.972 13.9116 25.371 16.2394 24.5199L33.1686 18.3297L38.538 6.76998L42.7703 5.22242L55.1507 39.0808L50.9185 40.6283L39.3588 35.2588L22.4296 41.449C21.3071 41.8595 20.0676 41.8072 18.9836 41.3037C17.8997 40.8002 17.0602 39.8868 16.6497 38.7643L12.4174 40.3119L9.32234 31.8473L13.5546 30.2997ZM37.4478 43.1548L41.3167 53.7356L34.9682 56.0569L27.5654 46.7684L24.7509 47.7975L23.2034 43.5652L40.1325 37.375L41.6801 41.6073L37.4478 43.1548Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id="paint0_linear_118_16"
              x1="32.5"
              y1="0"
              x2="32.5"
              y2="65"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#97FB4D" />
              <stop offset="1" stop-color="#5A952E" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      {/* Floating announcements container */}
      {isOpen && (
        <div className="annc-wrap">
          {/* Map untuk setiap floating announcement */}
          {announcements.slice(0, 2).map((announcement) => (
            <div
              key={announcement.id}
              className={`floating-announcement ${isHiding ? "hide" : ""}`}
              ref={announcementRef}
            >
              {/* Isi pengumuman */}
              <div className="announcement-item">
                <h4>{announcement.title}</h4>
                <p>{announcement.announcementText}</p>
                {announcement.imageUrl && (
                  <img
                    src={announcement.imageUrl}
                    alt="Announcement"
                    className="announcement-image"
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Announcement;
