import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Parallax } from "react-parallax";
import Navbar from "../layout/Navbarrlpf";
import Preloader from "../layout/preloader";
import ScrollToTopBtn from "../layout/ScrollToTop";
import { createGlobalStyle } from "styled-components";

const image1 = "../img/background/bglogin.webp";

const GlobalStyles = createGlobalStyle`
  .react-parallax-bgimage {
    transform: translate3d(-50%, 0, 0px) !important;
  }
`;

export default function Transaction2() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const loader = document.getElementById("mainpreloader");
      if (loader)
        setTimeout(() => {
          loader.classList.add("fadeOut");
          loader.style.display = "none";
        }, 600);
    }
  }, []);

  return (
    <>
      <Helmet>
        <link rel="icon" href="../img/icon.png" />
        <title>Transaction | Primefussion - Gateway to Premium Access!</title>
      </Helmet>

      <GlobalStyles />

      <div id="mainpreloader">
        <Preloader />
      </div>

      <div className="home dark-scheme">
        <header id="header-wrap">
          <Navbar />
        </header>

        <Parallax className="" bgImage={image1} strength={5}>
          <div className="de-gradient-edge-top"></div>
          <div className="de-gradient-edge-bottom"></div>
          <section className="no-bg transaction-section">
            <div className="container z-9">
              <div className="row align-items-center">
                <div className="col-lg-8 offset-lg-2">
                  <div className="transaction-card">
                    <div className="head d-flex justify-content-between mb-4">
                      <div className="left-info">
                        <h6>
                          <span className="context">Merchant: </span> Merchant
                          Sandbox
                        </h6>
                        <h6>
                          <span className="context">No Invoice: </span>{" "}
                          INV648293
                        </h6>
                        <h6>
                          <span className="context">Nama Pelanggan: </span> Evv
                        </h6>
                        <h6>
                          <span className="context">Email: </span> Evv@mail.com
                        </h6>
                      </div>
                      <div className="right-info text-end d-flex flex-column">
                        <div>
                          <h6>
                            <span className="context">Nomor Referensi:</span>
                          </h6>
                          <h6>DEV-T34361175091GLJ68</h6>
                        </div>
                        <div className="status mt-auto">
                          <h6>UNPAID</h6>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="main">
                      <div className="text-trans mt-4 text-center mb-4">
                        <h5>Pembayaran dengan QRIS Custom by ShopeePay</h5>
                        <p>
                          Pastikan anda melakukan pembayaran sebelum melewati
                          batas pembayaran dan dengan nominal yang tepat
                        </p>
                      </div>

                      <div className="payment">
                        <div className="expired">
                          <p>Batas Pembayaran</p>
                          <h5>06 September 2024 22:25 WIB</h5>
                        </div>
                          {/* <div className="code-payment">
                            <p>Kode Bayar/Nomor VA</p>
                            <h5>988917281949839</h5>
                          </div> */}
                          <div className="barcode">
                            <img src="img/barcode/barcode.png" alt="Barcode" />
                          </div>
                        <div className="amount text-center mt-2">
                          <h6>Jumlah Tagihan:</h6>
                          <h5>Rp 85.000</h5>
                        </div>
                      </div>

                      <div className="rincian-trans">
                        <p>Rincian Transaksi:</p>
                        <hr />
                        <div className="d-flex justify-content-between">
                          <h6>+ \\ALLACCSES90D - Allaccess 90D</h6>
                          <h5>Rp85.000</h5>
                        </div>
                        <div className="d-flex justify-content-between">
                          <h6>+ Biaya Administrasi</h6>
                          <h5>Rp1.345</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="footer d-flex justify-content-center align-items-center mt-2">
                    <p>Secure Payment by</p>
                    <img src="../img/payments/tripay-logo.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Parallax>
      </div>
    </>
  );
}
