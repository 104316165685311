import React from "react";

const Help = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-8 mb-2">
          <div className="subtitle mb-3">Got Questions?</div>
          <h2 className="mb20">Kami Punya Jawabannya!</h2>
        </div>

        <div className="clearfix"></div>

        <div className="col-lg-6">
          <div className="accordion accordion-flush" id="accflush">
            <div className="accordion-item">
              <h3 className="accordion-header" id="flush-h1">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-c1"
                  aria-expanded="false"
                  aria-controls="flush-c1"
                >
                  Apa itu PrimeFussion?
                </button>
              </h3>
              <div
                id="flush-c1"
                className="accordion-collapse collapse"
                aria-labelledby="flush-h1"
                data-bs-parent="#accflush"
              >
                <div className="accordion-body">
                  <p>
                    PrimeFussion adalah platform all-in-one yang memberikan
                    akses ke berbagai aplikasi premium dengan satu langganan.
                    Dari hiburan hingga produktivitas, semua aplikasi favorit
                    Anda tersedia dalam satu tempat dengan harga yang
                    terjangkau.
                  </p>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h3 className="accordion-header" id="flush-h2">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-c2"
                  aria-expanded="false"
                  aria-controls="flush-c2"
                >
                  Bagaimana cara kerja PrimeFussion?
                </button>
              </h3>
              <div
                id="flush-c2"
                className="accordion-collapse collapse"
                aria-labelledby="flush-h2"
                data-bs-parent="#accflush"
              >
                <div className="accordion-body">
                  <p>
                    Setelah berlangganan, Anda cukup memasang ekstensi browser.
                    Setelah itu, Anda langsung bisa menikmati akses ke semua
                    aplikasi premium yang ada dalam paket langganan Anda.
                  </p>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h3 className="accordion-header" id="flush-h3">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-c3"
                  aria-expanded="false"
                  aria-controls="flush-c3"
                >
                  Bagaimana pembayaran?
                </button>
              </h3>
              <div
                id="flush-c3"
                className="accordion-collapse collapse"
                aria-labelledby="flush-h3"
                data-bs-parent="#accflush"
              >
                <div className="accordion-body">
                  <p>
                    Setelah memilih paket langganan yang Anda inginkan, Anda
                    bisa melanjutkan dengan mengonfirmasi pesanan. Pilih metode
                    pembayaran yang tersedia, lalu masukkan kode diskon jika
                    Anda memilikinya. Setelah itu, klik Buat Pesanan dan
                    selesaikan pembayaran melalui metode yang Anda pilih. Mudah
                    dan cepat!
                  </p>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h3 className="accordion-header" i4="flush-h3">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-c4"
                  aria-expanded="false"
                  aria-controls="flush-c4"
                >
                  Kategori Berlangganan?
                </button>
              </h3>
              <div
                id="flush-c4"
                className="accordion-collapse collapse"
                aria-labelledby="flush-h4"
                data-bs-parent="#accflush"
              >
                <div className="accordion-body">
                  <p>
                    PrimeFussion menyediakan akses ke berbagai aplikasi premium
                    dalam beberapa kategori:
                  </p>
                  <ul>
                    <li>
                      <strong>All Access:</strong> Memberikan akses penuh ke
                      aplikasi hiburan dan produktivitas premium dalam satu
                      paket. Cocok untuk pengguna yang ingin menikmati keduanya
                      tanpa batas.
                    </li>
                    <li>
                      <strong>Productivity:</strong> Akses ke aplikasi-aplikasi
                      yang mendukung produktivitas, termasuk alat pembelajaran,
                      pengelolaan tugas, dan alat profesional.
                    </li>
                    <li>
                      <strong>Entertain:</strong> Akses ke aplikasi hiburan
                      premium seperti streaming film, acara TV, musik, dan
                      konten hiburan lainnya.
                    </li>
                  </ul>
                  {/* <p>
                    Cek daftar produk terbaru di halaman Product List untuk
                    pilihan aplikasi yang selalu diperbarui!
                  </p> */}
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h3 className="accordion-header" id="flush-h5">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-c5"
                  aria-expanded="false"
                  aria-controls="flush-c5"
                >
                  Redeem Code dan Discount Code?
                </button>
              </h3>
              <div
                id="flush-c5"
                className="accordion-collapse collapse"
                aria-labelledby="flush-h5"
                data-bs-parent="#accflush"
              >
                <div className="accordion-body">
                  <p>
                    <strong>Discount Code:</strong> Kode yang bisa Anda masukkan saat melakukan pembelian untuk mendapatkan potongan harga. Masukkan kode ini saat checkout untuk mengurangi biaya total pesanan Anda.
                  </p>
                  <p>
                    <strong>Redeem Code:</strong> Kode yang bisa Anda masukkan di bagian dashboard untuk mendapatkan akses aplikasi premium. Kode ini memberikan hak akses langsung ke layanan yang ditentukan.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="accordion accordion-flush" id="accflush1">
            <div className="accordion-item">
              <h3 className="accordion-header" id="flush-h1-1">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-c1-1"
                  aria-expanded="false"
                  aria-controls="flush-c1-1"
                >
                  Bisakah saya ganti paket?
                </button>
              </h3>
              <div
                id="flush-c1-1"
                className="accordion-collapse collapse"
                aria-labelledby="flush-h1-1"
                data-bs-parent="#accflush1"
              >
                <div className="accordion-body">
                  <p>
                    Saat ini, Anda tidak dapat mengganti paket langganan yang
                    sedang aktif. Namun, Anda bisa memilih paket baru kapan
                    saja, dan keduanya akan berjalan secara bersamaan.
                  </p>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h3 className="accordion-header" id="flush-h2-1">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-c2-1"
                  aria-expanded="false"
                  aria-controls="flush-c2-1"
                >
                  Bisa berbagi akun?
                </button>
              </h3>
              <div
                id="flush-c2-1"
                className="accordion-collapse collapse"
                aria-labelledby="flush-h2-1"
                data-bs-parent="#accflush1"
              >
                <div className="accordion-body">
                  <p>
                    Nope, sorry! Akun PrimeFussion hanya untuk satu pengguna dan
                    tidak boleh dibagikan. Untuk penggunaan bersama, setiap
                    orang harus memiliki akun sendiri agar tetap sesuai dengan
                    ketentuan layanan.
                  </p>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h3 className="accordion-header" id="flush-h4-1">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-c4-1"
                  aria-expanded="false"
                  aria-controls="flush-c4-1"
                >
                  Apakah aplikasi diperbarui?
                </button>
              </h3>
              <div
                id="flush-c4-1"
                className="accordion-collapse collapse"
                aria-labelledby="flush-h4-1"
                data-bs-parent="#accflush1"
              >
                <div className="accordion-body">
                  <p>
                    Ya, dengan langganan PrimeFussion, Anda akan mendapatkan pembaruan aplikasi secara otomatis tanpa biaya tambahan.
                  </p>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h3 className="accordion-header" id="flush-h3-1">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-c3-1"
                  aria-expanded="false"
                  aria-controls="flush-c3-1"
                >
                  Butuh bantuan?
                </button>
              </h3>
              <div
                id="flush-c3-1"
                className="accordion-collapse collapse"
                aria-labelledby="flush-h3-1"
                data-bs-parent="#accflush1"
              >
                <div className="accordion-body">
                  <p>
                    Tenang saja, kami siap membantu 24/7! Baik itu masalah
                    teknis atau pertanyaan seputar akun, tim dukungan kami
                    selalu siap sedia. Hubungi kami kapan saja, dan kami akan
                    memastikan pengalaman Anda dengan PrimeFussion tetap
                    berjalan lancar dan menyenangkan.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
