import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Parallax } from "react-parallax";
import { createGlobalStyle } from "styled-components";
import Navbar from "../layout/Navbarrlpf";
import toast, { Toaster } from 'react-hot-toast'; // Import react-hot-toast
import Preloader from "../layout/preloader"; // Import Preloader
import { Helmet } from "react-helmet-async";

const image1 = "../img/background/bglogin.webp";

const GlobalStyles = createGlobalStyle`
  .react-parallax-bgimage {
    transform: translate3d(-50%, 0, 0px) !important;
  }
`;

export default function TransactionDetail() {
  const { reference } = useParams();
  const [transactionDetail, setTransactionDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [countdown, setCountdown] = useState(5);
  const [isPaid, setIsPaid] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const navigate = useNavigate();

  // Fungsi untuk mengambil detail transaksi
  const fetchTransactionDetail = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/payment/transaction/detail/${reference}`
      );
      console.log('Transaction detail response:', response.data);
      setTransactionDetail(response.data.data);

      // Jika status PAID, mulai countdown dan tampilkan toast
      if (response.data.data.status === 'PAID') {
        setIsPaid(true); // Set PAID ke true
        toast.success('Pembayaran berhasil! Anda akan diarahkan ke dashboard dalam beberapa detik.'); // Tampilkan toast
      }
    } catch (error) {
      setError("Error fetching transaction detail");
      console.error("Error fetching transaction detail:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fungsi untuk menghitung waktu tersisa pembayaran
  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const timeDifference = transactionDetail.expired_time * 1000 - now;

    if (timeDifference > 0) {
      const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setTimeLeft({ hours, minutes, seconds });
    } else {
      setTimeLeft(null); // Jika waktu habis
    }
  };

  // Menggunakan useEffect untuk polling data transaksi secara berkala
  useEffect(() => {
    fetchTransactionDetail(); // Panggil API pertama kali

    const intervalId = setInterval(() => {
      fetchTransactionDetail();
    }, 30000);

    // Hentikan interval ketika komponen di-unmount
    return () => clearInterval(intervalId);
  }, [reference]);

  useEffect(() => {
    if (transactionDetail) {
      calculateTimeLeft(); // Hitung waktu tersisa saat transaksi diambil
      const countdownInterval = setInterval(() => {
        calculateTimeLeft();
      }, 1000); // Hitung mundur setiap detik

      return () => clearInterval(countdownInterval); // Hentikan interval ketika komponen di-unmount
    }
  }, [transactionDetail]);

  // Menggunakan useEffect untuk countdown ketika status PAID
  useEffect(() => {
    if (isPaid) {
      // Jika status PAID, mulailah hitung mundur
      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1); // Mengurangi angka countdown

        // Ketika countdown selesai (0 detik), redirect ke /tools
        if (countdown === 1) {
          clearInterval(countdownInterval);
          navigate("/dashboard");
        }
      }, 1000); // Hitung mundur setiap 1 detik

      // Bersihkan interval jika komponen di-unmount sebelum countdown selesai
      return () => clearInterval(countdownInterval);
    }
  }, [isPaid, countdown, navigate]);

  // Tampilkan Preloader jika loading masih true
  if (loading) {
    return <Preloader />; // Tampilkan Preloader saat data belum siap
  }

  return (
    <div>
      <GlobalStyles />

      <Helmet>
        <link rel="icon" href="/img/icon.png" />
        <title>Detail Transaksi | PrimeFussion - All-in-One Premium Access</title>
        <meta name="description" content="Lihat detail pembayaran dan status transaksi Anda di PrimeFussion. Pastikan semua informasi pembayaran sudah benar." />
      </Helmet>

      <div className="home dark-scheme">
        <header id="header-wrap">
          <Navbar />
        </header>

        <Parallax className="" bgImage={image1} strength={5}>
          <div className="de-gradient-edge-top"></div>
          <div className="de-gradient-edge-bottom"></div>
          <section className="no-bg transaction-section">
            <div className="container z-9">
              <div className="row align-items-center">
                <div className="col-lg-8 offset-lg-2">
                {!isPaid && (  // Teks hanya muncul jika belum PAID
                  <div className="refres-text d-flex justify-content-end mt-2 mb-2 me-4"> 
                    <h6 
                      className="refres-text text-end" 
                      style={{ cursor: 'pointer' }} 
                      onClick={fetchTransactionDetail} // Refresh secara manual
                    >
                      Sudah Melakukan Pembayaran?
                    </h6>
                  </div>
                )}
                {isPaid && (
                  <div className="countdown mt-4 mb-4 text-center">
                    <h5>Redirecting in {countdown} seconds...</h5>
                  </div>
                )}
                  {transactionDetail && (
                      <div className="transaction-card">
                      <div className="head d-flex justify-content-between mb-4">
                          <div className="left-info">
                              <h6><span className="context">No Invoice: </span> {transactionDetail.merchant_ref}</h6>
                              <h6><span className="context">Nama Pelanggan: </span> {transactionDetail.customer_name}</h6>
                              <h6><span className="context">Email: </span> {transactionDetail.customer_email}</h6>
                          </div>
                          <div className="right-info text-end d-flex flex-column">
                              <div>
                                  <h6><span className="context">Nomor Referensi:</span></h6>
                                  <h6>{transactionDetail.reference}</h6>
                              </div>
                              <div className="status mt-auto">
                                  <h6
                                      className={
                                          transactionDetail.status === 'PAID' ? 'paid-status' :
                                          transactionDetail.status === 'UNPAID' ? 'unpaid-status' :
                                          transactionDetail.status === 'FAILED' ? 'failed-status' : ''
                                      }
                                  >
                                      {transactionDetail.status}
                                  </h6>
                              </div>
                          </div>
                      </div>
                      <hr />
                      <div className="main">
                          <div className="text-trans mt-4 text-center mb-4">
                              <h5>Pembayaran dengan {transactionDetail.payment_name}</h5>
                              <p>Pastikan anda melakukan pembayaran sebelum melewati batas pembayaran dan dengan nominal yang tepat</p>
                          </div>
              
                          <div className="payment">
                              <div className="countdown-expired text-center">
                                  <p>Selesaikan pembayaran dalam</p>
                                  <h4>
                                      {timeLeft ? (
                                          `${timeLeft.hours} : ${timeLeft.minutes} : ${timeLeft.seconds}`
                                      ) : (
                                          'Waktu pembayaran telah habis'
                                      )}
                                  </h4>
                              </div>
                              <div className="expired mt-2 mb-2">
                                  <p>Batas Pembayaran</p>
                                  <h5>
                                      {new Date(transactionDetail.expired_time * 1000).toLocaleString("id-ID", {
                                          timeZone: "Asia/Jakarta",
                                          day: 'numeric',
                                          month: 'short',
                                          year: 'numeric',
                                          hour: '2-digit',
                                          minute: '2-digit',
                                          hour12: false
                                      }) + " WIB"}
                                  </h5>
                              </div>
                              {transactionDetail.payment_method.includes("QRIS") ? (
                                  <div className="barcode">
                                      <img
                                          src={transactionDetail.qr_url}
                                          alt="Barcode"
                                          style={{ width: "200px", height: "200px" }}
                                      />
                                  </div>
                              ) : (
                                  <div className="code-payment">
                                      <p>Kode Bayar/Nomor VA</p>
                                      <h5>{transactionDetail.pay_code}</h5>
                                  </div>
                              )}
                              <div className="amount text-center mt-2">
                                  <h6>Jumlah Tagihan:</h6>
                                  <h5>Rp{transactionDetail.amount.toLocaleString("id-ID")}</h5>
                              </div>
                          </div>
              
                          <div className="rincian-trans">
                              <p>Rincian Transaksi:</p>
                              <hr />
                              {transactionDetail.order_items.map((item, idx) => (
                                  <div className="d-flex justify-content-between" key={idx}>
                                      <h6>+ {item.name}</h6>
                                      <h5>Rp {item.subtotal.toLocaleString("id-ID")}</h5>
                                  </div>
                              ))}
                              {/* Menampilkan diskon jika ada */}
                              {transactionDetail.discount_amount > 0 && (
                                  <div className="d-flex justify-content-between">
                                      <h6>- Diskon ({transactionDetail.discount_code})</h6>
                                      <h5>- Rp {transactionDetail.discount_amount.toLocaleString("id-ID")}</h5>
                                  </div>
                              )}
                              <div className="d-flex justify-content-between">
                                  <h6>+ Biaya Administrasi</h6>
                                  <h5>Rp {transactionDetail.fee_customer.toLocaleString("id-ID")}</h5>
                              </div>
                              <hr />
                              <div className="d-flex justify-content-between">
                                  <h5>Total</h5>
                                  <h4>Rp {(transactionDetail.amount + transactionDetail.fee_customer).toLocaleString("id-ID")}</h4>
                              </div>
                          </div>
                      </div>
                  </div>
                  )}
                  <div className="footer d-flex justify-content-center align-items-center mt-2">
                    <p>Secure Payment by</p>
                    <img src="../img/payments/tripay-logo.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Parallax>
      </div>

      {/* Toaster component to show toast notifications */}
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
}
