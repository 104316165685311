import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const formatRupiah = (number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "decimal",
    maximumFractionDigits: 0,
  }).format(number);
};

const Plan = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/plans`);
        if (!response.ok) {
          throw new Error("Failed to fetch data from the server");
        }
        const data = await response.json();

        // Sort plans by price from cheapest to most expensive
        const sortedPlans = data.sort((a, b) => a.price - b.price);
        setPlans(sortedPlans);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <div className="de-gradient-edge-top"></div>
      <div className="de-gradient-edge-bottom"></div>
      <div className="container z-9">
        <div className="row">
          <div className="col-12 text-center mb-4">
            <div className="subtitle mb-3">Pick Your Plan</div>
            <h2 className="mb20">Harga Hemat, Akses Maksimal!</h2>
          </div>

          {plans.map((plan) => (
            <div
              className="col-xl-3 col-md-6 mb-sm-30 fadeInRight"
              key={plan.id}
            >
              <div
                className={`de_pricing-table type-2 ${
                  plan.bestSeller ? "rec" : ""
                }`}
              >
                {plan.bestSeller && (
                  <div className="d-recommend">Best Seller</div>
                )}
                <div className="d-head">
                  <h3>{plan.name}</h3>
                </div>
                <div className="d-price">
                  <h4>
                    <span className="sp-span">Rp</span>
                    {formatRupiah(plan.price)}
                    <span>/{plan.duration} Days</span>
                  </h4>
                  <p>
                  <s>Rp{formatRupiah(plan.normalPrice)}</s>
                  </p>
                </div>
                <div className="d-location mb-4">
                  <h4>{plan.description}</h4>
                </div>
                <div className="d-group">
                  <h4>Top Features</h4>
                  <ul
                    className="d-list"
                    dangerouslySetInnerHTML={{ __html: plan.features }}
                  ></ul>
                </div>
                <div className="d-action">
                  <Link
                    to="/checkout"
                    className="btn-main w-100"
                    onClick={() => {
                      // Simpan plan yang dipilih ke dalam localStorage
                      localStorage.setItem(
                        "selectedPlan",
                        JSON.stringify(plan)
                      );
                    }}
                  >
                    Ambil Sekarang!
                  </Link>
                  <p>(Harga bisa berubah kapan saja)</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Plan;
