// dashboard.jsx

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Parallax } from "react-parallax";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Navbar from "../layout/Navbarrlpf";
import Preloader from "../layout/preloader";
import Footer from "../section-pages/pf/footerrlpf";
import { Modal, Button } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import AnnouncementUser from "../section-pages/pf/Announcement-user";

const image1 = "../img/background/bgheader.webp";

export default function Dashboard() {
  const [userData, setUserData] = useState({
    name: "",
    isPremium: false,
    premiumExpiry: "",
    planName: "",
    subscriptions: [],
  });

  const [showModal, setShowModal] = useState(false);
  const [showManualModal, setShowManualModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const [redeemCode, setRedeemCode] = useState("");
  const [redeemMessage, setRedeemMessage] = useState("");
  const [redeemSuccess, setRedeemSuccess] = useState(false); // Untuk validasi sukses atau gagal

  const handleShowRedeemModal = () => setShowRedeemModal(true);
  const handleCloseRedeemModal = () => setShowRedeemModal(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [newName, setNewName] = useState(userData.name); // State untuk menyimpan nama baru

  const handleShowEditModal = () => {
    setNewName(userData.name); // Isi field nama dengan nama pengguna saat ini
    setShowEditModal(true);
  };
  
  const handleCloseEditModal = () => setShowEditModal(false);


  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);

  const handleClosePasswordModal = () => setShowPasswordModal(false);
  const handleShowPasswordModal = () => setShowPasswordModal(true);


  // State untuk menyimpan data manual
  const [manualData, setManualData] = useState({
    name: "",
    email: "",
    password: "",
    url: "",
    deskripsi: "",
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      const loader = document.getElementById("mainpreloader");
      if (loader)
        setTimeout(() => {
          loader.classList.add("fadeOut");
          loader.style.display = "none";
        }, 600);
    }

    // Adding Crisp Chat Script
    // const crispScript = document.createElement("script");
    // crispScript.src = "https://client.crisp.chat/l.js";
    // crispScript.async = true;
    // crispScript.type = "text/javascript";
    // document.head.appendChild(crispScript);

    // window.$crisp = [];
    // window.CRISP_WEBSITE_ID = "2ed245ed-f518-4194-b1da-32b72f853acb";

    // Load Tawk.to script when the component mounts

    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/66e40fe4ea492f34bc130c05/1i7lds60s";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) return;

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/user/me`, // pastikan URL API sesuai
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        setUserData(response.data); // Pastikan semua subscriptions diterima
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token");
          navigate("/login");
        } else {
          console.error("Failed to fetch user data:", error);
        }
      }
    };

    fetchUserData();
  }, [navigate]);


  // Ambil query parameters dari URL
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const name = queryParams.get("name");
    const email = queryParams.get("login");
    const password = queryParams.get("password");
    const url = queryParams.get("url");
    const deskripsi = queryParams.get("deskripsi");

    if (name && email && password && url && deskripsi) {
      setManualData({ name, email, password, url, deskripsi });
      setShowManualModal(true);

      const urlWithoutParams = location.pathname;
      navigate(urlWithoutParams, { replace: true });
    }
  }, [location.search, navigate, location.pathname]);

  const handleApplyRedeemCode = async () => {
    try {
      const token = localStorage.getItem("token"); // Pastikan ada token user

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/voucher/apply-redeem`,
        {
          redeem_code: redeemCode, // Hanya kirim redeem_code
          user_email: userData.email, // Ambil email dari user data yang sudah login
        },
        {
          headers: { Authorization: `Bearer ${token}` }, // Tambahkan Authorization
        }
      );

      if (response.status === 200) {
        setRedeemMessage("Redeem code applied successfully!");
        setRedeemSuccess(true);
        toast.success("Redeem code berhasil diterapkan!");
        handleCloseRedeemModal();
        setTimeout(() => {
          window.location.reload(); // Refresh halaman setelah beberapa detik
        }, 1500);
      } else {
        setRedeemMessage("Failed to apply redeem code.");
        setRedeemSuccess(false);
        toast.error("Gagal menerapkan redeem code.");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setRedeemMessage("Invalid redeem code.");
        setRedeemSuccess(false);
        toast.error("Kode redeem tidak valid.");
      } else {
        setRedeemMessage("An error occurred while applying redeem code.");
        setRedeemSuccess(false);
        toast.error("Terjadi kesalahan saat menerapkan kode redeem.");
      }
    }
  };

  const handleUpdateName = async () => {
    if (newName.trim() === "") {
      toast.error("Nama tidak boleh kosong!");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/user/updateName`,
        { name: newName },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        toast.success("Nama berhasil diupdate!");
        setUserData({ ...userData, name: newName });
        handleCloseEditModal();
      }
    } catch (error) {
      toast.error("Terjadi kesalahan saat mengupdate nama.");
    }
  };

  const handleResetPassword = async () => {
    if (!currentPassword || !newPassword) {
      toast.error("Password lama dan password baru tidak boleh kosong");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/user/resetPassword`,
        { currentPassword, newPassword },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        toast.success("Password berhasil diupdate!");
        handleClosePasswordModal();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Terjadi kesalahan saat mengupdate password.");
    }
  };

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("token");

      if (token) {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/user/logout`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }

      // Hapus token dari localStorage
      localStorage.removeItem("token");

      // Arahkan ke halaman login
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const [currentDate, setCurrentDate] = useState("");

  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    const now = new Date();
    const hours = now.getHours();

    if (hours < 12) {
      setGreeting("Selamat Pagi 👋, ");
    } else if (hours < 18) {
      setGreeting("Selamat Siang 👋, ");
    } else {
      setGreeting("Selamat Malam 👋, ");
    }

    const dayName = now.toLocaleString("id-ID", { weekday: "long" });
    const monthName = now.toLocaleString("id-ID", { month: "long" });
    const date = `${dayName}, ${now.getDate()} ${monthName} ${now.getFullYear()}`;

    setCurrentDate(date);
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert("Copied to clipboard");
    });
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseManualModal = () => {
    setShowManualModal(false);

    const urlWithoutParams = location.pathname;
    navigate(urlWithoutParams, { replace: true });
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />

      {/* HEAD */}
      <Helmet>
        <link rel="icon" href="/img/icon.png" />
        <title>Dashboard | PrimeFussion - All-in-One Premium Access</title>
        <meta name="description" content="Kelola akun Anda dan akses aplikasi premium dengan mudah melalui dashboard PrimeFussion. Dapatkan akses ke lebih dari 50 aplikasi premium hanya dengan satu langganan." />
        <meta name="keywords" content="PrimeFussion, Dashboard, Akses Premium, Langganan, Aplikasi Premium" />
      </Helmet>

      {/* LOADER */}
      <div id="mainpreloader">
        <Preloader />
      </div>

      {/* MENU */}
      <div className="home dark-scheme">
        <header id="header-wrap">
          <Navbar />
        </header>

        <Parallax bgImage={image1} strength={5}>
          <section className="no-bg no-bottom">
            <div className="container d-flex justify-content-between align-items-end">
              <div className="subtitle mb-5">Dashboard Primefussion</div>
              <div className="wrap-date mb-5 text-end">
                {/* <p>
                  <span>{currentDate}</span>
                </p> */}
                <p>
                  <span className="greeting">
                  {greeting}
                  </span>{userData.name}
                  <br />
                  <span className="mail">// {userData.email}</span>
                </p>
              </div>
            </div>
          </section>
        </Parallax>

        <section className="no-top dashboard-section mt-5">
          <div className="container">
            {/* <div className="welcome mb-4">
              <div className="wrap-info">
                <p className="welcome-text">Wellcome back 👋</p>
                <h4>{userData.name}</h4>
                <p>{userData.email}</p>
              </div>
            </div> */}
            <div className="row">
              <div className="col-md-7">
                <div>
                  <h4 className="text-muted mb-4">#Plan</h4>
                  {userData.subscriptions.length > 0 ? (
                    userData.subscriptions.map((subscription, index) => {
                      // Tentukan kelas berdasarkan kategori plan
                      let cardClass = "";
                      switch (subscription.category) {
                        case "WORKING":
                          cardClass = "subscription-working";
                          break;
                        case "ENTERTAINMENT":
                          cardClass = "subscription-entertainment";
                          break;
                        case "ALLACCESS":
                          cardClass = "subscription-allaccess";
                          break;
                        default:
                          cardClass = "subscription-default";
                      }

                      return (
                        <div
                          key={index}
                          className={`premium-card mb-4 ${cardClass}`}
                        >
                          <div className="premium-ket d-flex justify-content-between">
                            <div className="premium-id">
                              #
                              {subscription.category
                                ? subscription.category
                                : "Unknown Plan"}
                            </div>
                            <div className="premium-expiry">
                              {subscription.expiryDate
                                ? `Expires on ${new Date(
                                    subscription.expiryDate
                                  ).toLocaleDateString()}`
                                : "No expiry date available"}
                            </div>
                          </div>
                          <div className="premium-type selected">
                            <h4>
                              {subscription.planName
                                ? subscription.planName
                                : "No Plan"}
                            </h4>
                            <p>{subscription.description}</p>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p>No active subscriptions.</p>
                  )}
                </div>
              </div>

              <div className="col-md-5">
                <h4 className="text-muted mb-4">#Menu</h4>
                <div className="row">
                  <div
                    className="tool-item d-flex"
                    onClick={handleShowModal}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fa fa-wrench" aria-hidden="true"></i>
                    <p>Instalasi</p>
                  </div>
                  <div
                    className="tool-item d-flex"
                    style={{ cursor: "pointer" }}
                    onClick={() => (window.location.href = "/checkout")}
                  >
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <p>Berlangganan Premium</p>
                  </div>
                  <div
                    className="tool-item d-flex"
                    onClick={handleShowRedeemModal}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fa fa-gift" aria-hidden="true"></i>
                    <p>Apply Redeem Code</p>
                  </div>
                  <div
                    className="tool-item d-flex"
                    onClick={() => (window.location.href = "/checkout?showDiscountForm=true")} // Add query parameter
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fa fa-percent" aria-hidden="true"></i>
                    <p>Apply Discount Code</p>
                  </div>
                  <div
                    className="tool-item d-flex"
                    style={{ cursor: "pointer" }}
                    onClick={handleShowEditModal}
                  >
                    <i className="fa fa-user-edit" aria-hidden="true"></i>
                    <p>Edit Nama</p>
                  </div>
                  <div
                    className="tool-item d-flex"
                    style={{ cursor: "pointer" }}
                    onClick={handleShowPasswordModal}
                  >
                    <i className="fa fa-lock" aria-hidden="true"></i>
                    <p>Update Password</p>
                  </div>
                  <div
                    className="tool-item red d-flex"
                    onClick={handleLogout}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fa fa-sign-out" aria-hidden="true"></i>
                    <p>Logout</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>

      {/* Modal untuk Manual Data */}
      <Modal
        show={showManualModal}
        onHide={handleCloseManualModal}
        centered
        className="custom-modal-manual"
      >
        <Modal.Header>
          <Modal.Title>
            {manualData.name && (
              <div className="name-apps mb-1">
                <h3>{manualData.name}</h3>
                <hr />
              </div>
            )}
          </Modal.Title>
          {manualData.deskripsi && <p>{manualData.deskripsi}</p>}
        </Modal.Header>
        <Modal.Body>
          {manualData.email && (
            <div className="email d-flex align-items-center mb-1">
              <p className="mb-0">
                <strong>Email</strong> : {manualData.email}
              </p>
              {/* <i
                className="fa fa-copy ml-2"
                style={{ cursor: "pointer" }}
                onClick={() => copyToClipboard(manualData.email)}
                title="Copy Email"
              /> */}
            </div>
          )}

          {manualData.password && (
            <div className="password d-flex align-items-center">
              <p className="mb-0">
                <strong>Password</strong> : {manualData.password}
              </p>
              {/* <i
                className="fa fa-copy ml-2"
                style={{ cursor: "pointer" }}
                onClick={() => copyToClipboard(manualData.password)}
                title="Copy Password"
              /> */}
            </div>
          )}

          {manualData.url && manualData.name && (
            <a
              className="btn-main mt30"
              href={manualData.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              LOGIN {manualData.name}
            </a>
          )}
        </Modal.Body>
      </Modal>

      {/* Modal untuk Tutorial Installation */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="lg"
        centered
        scrollable
        className="custom-modal"
      >
        <Modal.Header>
          <Modal.Title>Tutorial Instalasi</Modal.Title>
          <br />
        </Modal.Header>
        <Modal.Body>
        <h4 className="text-muted">#Video Tutorial Instalasi</h4>
        <hr />
        {/* <h5 className="mb-4">Comming Up!</h5> */}
          {/* Embed Video YouTube */}
          <div className="embed-responsive embed-responsive-16by9 mb-3">
            <iframe
              className="embed-responsive-item"
              src="https://www.youtube.com/embed/e8ow6-9Hgh0?si=76E9jbTH14Rs3X6d&amp;controls=0"
              allowFullScreen
              title="YouTube Video Tutorial"
            ></iframe>
          </div>

          <h4 className="text-muted">#Step Instalasi</h4>
          <hr />

          <h5>Step 1: Download File Ekstensi</h5>
          <ul>
            <li>Klik tombol di bawah ini untuk mengunduh file ekstensi.</li>
            <Button
              variant="primary"
              className="mt-2"
              href="https://short.primefussion.com/extension-download"
            >
              Download Extension
            </Button>
          </ul>
          

          <h5 className="mt-4">Step 2: Ekstrak File yang Diunduh</h5>
          <ul>
            <li>Setelah file diunduh, buka folder tempat file diunduh.</li>
            <li>Ekstrak file ZIP ke lokasi di komputer yang mudah ditemukan.</li>
          </ul>

          <h5 className="mt-4">Step 3: Aktifkan Developer Mode di Chrome</h5>
          <ul>
            <li>Buka Chrome dan klik menu titik tiga di pojok kanan atas.</li>
            <li>Pilih <strong>Ekstensi</strong>, atau kunjungi <a href="chrome://extensions/" target="_blank" rel="noopener noreferrer"><strong>chrome://extensions</strong></a>.</li>
            <li>Aktifkan <strong>Mode Pengembang</strong> di pojok kanan atas dengan menggeser switch.</li>
          </ul>

          <h5 className="mt-4">Step 4: Install Ekstensi</h5>
          <ul>
            <li>Pada halaman ekstensi Chrome, klik <strong>Load unpacked</strong>.</li>
            <li>Arahkan ke folder tempat kamu mengekstrak file ekstensi.</li>
            <li>Pilih folder tersebut dan klik <strong>Open</strong>.</li>
          </ul>

          <h5 className="mt-4">Gunakan Extension</h5>
          <ul>
            <li>Klik ikon *puzzle* (ekstensi) di sebelah kanan atas sebelah profile Chrome.</li>
            <li>Cari ekstensi yang baru dipasang di daftar.</li>
            <li>Klik ikon *pin* (pin) di sebelah nama ekstensi untuk menambahkan ekstensi ke toolbar Chrome.</li>
          </ul>
            <hr />
          <p>
            Jika mengalami masalah selama instalasi, silakan lihat video panduan atau hubungi tim dukungan kami.
          </p>
        </Modal.Body>
      </Modal>

      <Modal
        show={showRedeemModal}
        onHide={handleCloseRedeemModal}
        centered
        className="custom-modal"
      >
        <Modal.Header>
          <Modal.Title>Apply Redeem Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="redeem-code-form mt-2">
            <form className="form-dark" id="form_redeem" method="post">
              <div className="form-group">
                <input
                  className="form-control"
                  id="redeem_code_input"
                  name="redeem_code"
                  placeholder="Masukkan kode redeem"
                  type="text"
                  value={redeemCode}
                  onChange={(e) => setRedeemCode(e.target.value)}
                />
                <div className="apply-btn-wrapper">
                  <a
                    id="btn-apply-redeem"
                    onClick={handleApplyRedeemCode}
                    style={{ cursor: "pointer" }}
                  >
                    Apply
                  </a>
                </div>
              </div>
              <div className="clearfix"></div>

              {redeemMessage && (
                <div className="mt-2">
                  <p className={redeemSuccess ? "text-success" : "text-danger"}>
                    {redeemMessage}
                  </p>
                </div>
              )}
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showEditModal} onHide={handleCloseEditModal} centered className="edit-account-modal">
        <Modal.Body>
        <h4>#Edit Nama</h4>
        <hr />
          <div className="form-group">
          <input
              type="text"
              className="form-control mt-2"
              value={newName} // Menampilkan nama pengguna yang sudah ada
              onChange={(e) => setNewName(e.target.value)} // Memungkinkan pengeditan atau penghapusan
              placeholder="Masukkan nama baru"
            />
          </div>
          <div className="form-group mt-4">
            <input
              type="email"
              className="form-control disabled-input"
              value={userData.email}
              disabled
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-save" onClick={handleUpdateName}>
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPasswordModal} onHide={handleClosePasswordModal} centered className="password-modal">
        <Modal.Body>
        <h4>#Update Password</h4>
        <hr />
          <div className="form-group">
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control mt-2"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder="Masukkan password lama"
              />
              <span
                className="input-group-text toggle-visibility"
                onClick={togglePasswordVisibility}
                style={{ cursor: "pointer" }}
              >
                <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`} aria-hidden="true"></i>
              </span>
            </div>
          </div>
          <div className="form-group mt-3">
            <div className="input-group">
              <input
                type={showNewPassword ? "text" : "password"}
                className="form-control"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Masukkan password baru"
              />
              <span
                className="input-group-text toggle-visibility"
                onClick={toggleNewPasswordVisibility}
                style={{ cursor: "pointer" }}
              >
                <i className={`fa ${showNewPassword ? 'fa-eye-slash' : 'fa-eye'}`} aria-hidden="true"></i>
              </span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="btn-save" onClick={handleResetPassword}>
            Simpan Password
          </Button>
        </Modal.Footer>
      </Modal>
      <AnnouncementUser delay={2000} />
    </>
  );
}
