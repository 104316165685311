import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

const AnnouncementUser = ({ delay = 5000 }) => {
  const [banners, setBanners] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isHiding, setIsHiding] = useState(false);
  const announcementRef = useRef(null);

  // Fetch data banner dari backend
  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/banners`
        );
        // Filter hanya yang pinned: true
        const pinnedBanners = response.data.filter(
          (banner) => banner.pin
        );
        setBanners(pinnedBanners);
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    };

    fetchBanners();
  }, []);

  // Open announcement automatically after delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  // Handle click outside to close announcement
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        announcementRef.current &&
        !announcementRef.current.contains(event.target)
      ) {
        closeAnnouncement();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [announcementRef]);

  // Function to close the announcement with animation
  const closeAnnouncement = () => {
    setIsHiding(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsHiding(false);
    }, 500);
  };

  // Toggle for opening/closing announcement
  const toggleAnnouncement = () => {
    if (isOpen) {
      closeAnnouncement();
    } else {
      setIsOpen(true);
    }
  };

  // If no banners, don't show anything
  if (banners.length === 0) {
    return null;
  }

  return (
    <div className="announcement-wrapper">
      <div className="announcement-icon" onClick={toggleAnnouncement}>
        {/* SVG icon here */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="65"
          height="65"
          viewBox="0 0 65 65"
          fill="none"
        >
          <path d="M0 0L65 65H0V0Z" fill="url(#paint0_linear_118_16)" />
          <path
            d="M13.5546 30.2997C12.7035 27.972 13.9116 25.371 16.2394 24.5199L33.1686 18.3297L38.538 6.76998L42.7703 5.22242L55.1507 39.0808L50.9185 40.6283L39.3588 35.2588L22.4296 41.449C21.3071 41.8595 20.0676 41.8072 18.9836 41.3037C17.8997 40.8002 17.0602 39.8868 16.6497 38.7643L12.4174 40.3119L9.32234 31.8473L13.5546 30.2997ZM37.4478 43.1548L41.3167 53.7356L34.9682 56.0569L27.5654 46.7684L24.7509 47.7975L23.2034 43.5652L40.1325 37.375L41.6801 41.6073L37.4478 43.1548Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id="paint0_linear_118_16"
              x1="32.5"
              y1="0"
              x2="32.5"
              y2="65"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#97FB4D" />
              <stop offset="1" stop-color="#5A952E" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      {/* Floating announcements container */}
      {isOpen && (
        <div className="annc-wrap">
          {banners.slice(0, 2).map((banner) => (
            <div
              key={banner.id}
              className={`floating-announcement ${isHiding ? "hide" : ""}`}
              ref={announcementRef}
            >
              <div className="announcement-item">
                <h4>{banner.text}</h4>
                <p>{banner.description}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AnnouncementUser;
