import React from "react";

const Section = () => {
  return (
    <>
  
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className="subtitle mb-3">Why Go PrimeFussion?</div>
            <h2 className="mb-5">Pilihan Cerdas untuk Akses Premium</h2>
          </div>

          <div className="col-lg-3 col-md-6 mb-sm-20">
            <div>
              <img src="./img/icons/icon-rocket.svg" className="mb20" alt="" />
              <h4>Instant Access</h4>
              <p>
              Masuk langsung ke aplikasi premium favorit Anda hanya dengan satu klik. Tanpa menunggu, langsung nikmati!
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mb-sm-20">
            <div>
              <img src="./img/icons/icon-babi.svg" className="mb20" alt="" />
              <h4>Budget-Friendly</h4>
              <p>
              Satu langganan untuk semua aplikasi top. Hemat waktu dan uang, tanpa perlu langganan satu-satu.
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mb-sm-20">
            <div>
              <img src="./img/icons/icon-secure.svg" className="mb20" alt="" />
              <h4>Secure & Private</h4>
              <p>
              Gak perlu khawatir soal data pribadi PrimeFussion mengutamakan keamanan dan privasimu.
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mb-sm-20">
            <div>
              <img src="./img/icons/icon-24h.svg" className="mb20" alt="" />
              <h4>Support 24/7</h4>
              <p>
                Bingung? Butuh bantuan? Tim support kami selalu siap membantu kamu kapan pun, di mana pun, 24/7!
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section;
