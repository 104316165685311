import React from "react";

const About = () => {
  return (
    <section className="about-section">
      <div className="container">
        <div className="row align-items-center gx-5">
          <div className="col-lg-6 position-relative">
            <div className="images-deco-1">
              <img src="/img/misc/pf1x.png" className="d-img-1" alt="" />
              <img src="/img/misc/1pff.png" className="d-img-2" alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="subtitle mb20">About PrimeFussion</div>
            <h2>
              All-in-One <br /> Premium Access
            </h2>
            <p>
            ── PrimeFussion adalah platform all-in-one yang memudahkan akses ke berbagai aplikasi premium. Dari hiburan hingga produktivitas, semuanya bisa diakses dalam satu platform dengan harga yang sangat terjangkau. Tidak perlu lagi mengelola banyak langganan—cukup satu langganan untuk semua kebutuhan digitalmu!
            </p>
            <p>
              Kami menyediakan akses mudah dan aman, tanpa perlu banyak akun
              atau biaya berlebih. Didukung layanan 24/7, PrimeFussion
              memastikan pengalaman premium kamu berjalan lancar.
            </p>
            <p>Akses semua aplikasi premium favoritmu, hanya dengan satu langganan!</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
