import React, {useState} from "react";
import { Parallax } from "react-parallax";
import YouTubePlayer from "./youtubeplayer";
import { Modal, Button } from "react-bootstrap";

const Section = () => {
  const videoId = "P1CabD-8ULc";
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  return (
    <>
    <Parallax className="home-video">
      <div className="iframeyoutube">
        <YouTubePlayer videoId={videoId} />
      </div>

      <div className="de-gradient-edge-top"></div>
      <div className="de-gradient-edge-bottom"></div>
      <div className="overlay-bg t50 no-top no-bottom">
        <div className="v-center">
          <div className="container z-1000">
            <div className="row align-items-center">
              <div className="col-lg-10 offset-lg-1 text-center">
                <div className="subtitle blink mb-3">
                Hai 👋, Selamat Datang di PrimeFussion!
                </div>
                <h1 className="mb-1 ">PrimeFussion</h1>
              </div>
              <div className="col-lg-6 offset-lg-3 text-center text-white">
                <p className="">
                Bosan dengan banyaknya langganan aplikasi premium yang bikin ribet?
                </p>
                <hr />
                <p>
                  <strong>Pakai PrimeFussion!</strong> -Platform all-in-one untuk semua aplikasi premium favoritmu, dari hiburan hingga produktivitas, semua ada dalam satu tempat dengan harga terjangkau!
                </p>
                <Button className="btn-main mt-3" onClick={handleShowModal}>
                    Cek Demo!
                  </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Parallax>


      {/* Modal untuk Demo */}
      <Modal show={showModal} onHide={handleCloseModal} size="xl" centered className="custom-modal"> 
        <Modal.Body>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              src="https://www.youtube.com/embed/cMTK-S0DLms?si=P-tsUwBwQXyrxLt3&amp;controls=0"
              allowFullScreen
              title="YouTube Video Demo"
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>
      </>
  );
};

export default Section;
