import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Parallax } from "react-parallax";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast'; // Import react-hot-toast
import Navbar from "../layout/Navbarrlpf";
import Preloader from "../layout/preloader";
import Footer from "../section-pages/pf/footerrlpf";

const image1 = "../img/background/bgheader.webp";

export default function Checkout() {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [paymentChannels, setPaymentChannels] = useState([]);
  const [user, setUser] = useState({
    name: "",
    email: "",
  });

  const [transactionResponse, setTransactionResponse] = useState(null); 

  const [discountCode, setDiscountCode] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0); 
  const [isDiscountValid, setIsDiscountValid] = useState(false); 
  const [discountError, setDiscountError] = useState(''); 
  const [showDiscountForm, setShowDiscountForm] = useState(false); // State untuk menampilkan form diskon
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Calculate Total Price
  const calculateTotalPrice = () => {
    if (!selectedPlan) {
      return 0;
    }

    let totalPrice = parseFloat(selectedPlan.price);

    // Kurangi dengan diskon jika valid
    if (isDiscountValid) {
      totalPrice -= discountAmount;
    }

    return Math.round(totalPrice).toLocaleString("id-ID");
  };

  // Fetch User, Plans, and Payment Channels
useEffect(() => {
  const fetchAllData = async () => {
    try {
      // Fetch user data from localStorage
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        setUser(JSON.parse(storedUser));
      }

      // Fetch payment channels
      const paymentResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/payment/channels`);
      const channels = paymentResponse.data.data;
      setPaymentChannels(channels);

      // Jika hanya ada satu payment channel, pilih otomatis
      if (channels.length === 1) {
        setSelectedPaymentMethod(channels[0].code); // Otomatis memilih QRIS atau metode pembayaran pertama
      }

      // Fetch plans
      const planResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/plans`);
      if (Array.isArray(planResponse.data)) {
        const sortedPlans = planResponse.data.sort((a, b) => a.price - b.price);
        setPlans(sortedPlans);
      } else {
        setError("Unexpected data format");
      }

      // Fetch selected plan from localStorage
      const storedPlan = localStorage.getItem("selectedPlan");
      if (storedPlan) {
        const parsedPlan = JSON.parse(storedPlan);
        setSelectedPlan(parsedPlan);
      }

    } catch (error) {
      setError("Failed to fetch data from the server");
    } finally {
      setLoading(false);
    }
  };

  // Bagian ini adalah tambahan untuk memeriksa query parameter
  const params = new URLSearchParams(window.location.search);
  if (params.get('showDiscountForm') === 'true') {
    setShowDiscountForm(true); // Otomatis membuka form diskon
  }

  fetchAllData();
}, []);


  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  
    // Reset diskon ketika plan diganti
    setDiscountAmount(0);         // Reset jumlah diskon
    setIsDiscountValid(false);    // Reset validasi diskon
    setDiscountCode('');          // Kosongkan input kode diskon
    setDiscountError('');         // Kosongkan pesan error
  
    localStorage.setItem("selectedPlan", JSON.stringify(plan));
  };
  
  const handlePaymentMethodSelect = (methodId) => {
    setSelectedPaymentMethod(methodId);
  };

  const handleCheckDiscount = async () => {
    if (!discountCode) {
      setDiscountError("Silakan masukkan kode diskon.");
      setIsDiscountValid(false);
      toast.error("Silakan masukkan kode diskon."); // Tambahkan toast untuk error ini
      return;
    }
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/voucher/check-discount`, {
        discount_code: discountCode,
        plan_id: selectedPlan.id // ID plan yang dipilih
      });
  
      if (response.data && response.data.discount_amount) {
        setDiscountAmount(response.data.discount_amount); // Set diskon yang valid
        setIsDiscountValid(true); // Diskon valid
        setDiscountError(""); // Reset error jika tidak ada
        toast.success('Diskon berhasil diterapkan'); // Menampilkan toast sukses
      } else {
        setDiscountAmount(0); // Reset jika tidak valid
        setIsDiscountValid(false);
        setDiscountError("Kode diskon tidak valid atau tidak berlaku untuk plan ini.");
        toast.error("Kode diskon tidak valid atau tidak berlaku"); // Menampilkan toast error
      }
    } catch (error) {
      setIsDiscountValid(false);
      
      const errorMessage = error.response && error.response.data && error.response.data.message 
        ? error.response.data.message 
        : "Gagal memeriksa kode diskon. Silakan coba lagi.";
  
      setDiscountError(errorMessage);
      toast.error(errorMessage); // Tampilkan error message di toast
    }
  };
  

  const handlePayNow = async () => {
    if (!selectedPlan || !selectedPaymentMethod) {
      toast.error("Silakan pilih metode pembayaran.");
      return;
  }

  const storedUser = localStorage.getItem("user");
  const parsedUser = storedUser ? JSON.parse(storedUser) : null;
  if (!parsedUser || !parsedUser.id) {
      toast.error("Pengguna tidak ditemukan.");
      return;
  }

  setIsSubmitting(true);

  const order_items = [
      {
          sku: selectedPlan.id,
          name: selectedPlan.name,
          quantity: 1
      }
  ];

  const transactionData = {
      method: selectedPaymentMethod,
      merchant_ref: "INV-PF-" + Math.floor(Math.random() * 1000000),
      customer_name: user.name,
      customer_email: user.email,
      order_items: order_items,
      userId: parsedUser.id,
      discount_code: discountCode || null,
  };

  try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/payment/create-transaction`, transactionData);

      if (response.data && response.data.tripay && response.data.tripay.data && response.data.tripay.data.reference) {
          const reference = response.data.tripay.data.reference;
          const domainUrl = process.env.REACT_APP_DOMAIN_URL;
          window.location.href = `${domainUrl}/transaction/${reference}`;
      } else {
          toast.error("Referensi pembayaran tidak ditemukan.");
          setIsSubmitting(false);
      }
  } catch (error) {
      const errorMessage = error.response?.data?.message || "Gagal membuat transaksi. Silakan coba lagi.";
      toast.error(errorMessage);
      setIsSubmitting(false);
  }
  };  

  // Jika isSubmitting true, tampilkan Preloader
  if (isSubmitting) {
    return <Preloader />; // Preloader ditampilkan selama proses transaksi berlangsung
  }

  // Tampilkan elemen loading jika semua data belum siap
  if (loading) {
    return <Preloader />; // Gunakan komponen loading untuk seluruh proses
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Helmet>
        <link rel="icon" href="/img/icon.png" />
        <title>Checkout | PrimeFussion - All-in-One Premium Access</title>
        <meta name="description" content="Selesaikan pembayaran Anda dan dapatkan akses instan ke aplikasi-aplikasi premium di PrimeFussion." />
      </Helmet>

      <div id="mainpreloader"></div>

      <div className="home dark-scheme">
        <header id="header-wrap">
          <Navbar />
        </header>

        <Parallax bgImage={image1} strength={5}>
          <section className="no-bg no-bottom">
            <div className="container d-flex justify-content-between">
              <div className="subtitle mb-5">Checkout Primefussion</div>
              <div>
                <a href="/dashboard" className="ml-auto btn-line">
                  Dashboard
                </a>
              </div>
            </div>
          </section>
        </Parallax>

        <section className="no-top checkout-section mb-5 mt-5">
          <div className="container">
            <div className="row">
              {/* Left Section: Choose Plan */}
              <div className="col-md-7">
                {/* Render Plan yang Dipilih */}
                {selectedPlan && (
                  <div>
                    <h4 className="mb-4">Plan terpilih</h4>
                    <div
                      key={selectedPlan.id}
                      className={`premium-card mb-4 selected subscription-${selectedPlan.category.toLowerCase()}`} // Tambahkan kelas kategori
                      onClick={() => handlePlanSelect(selectedPlan)}
                    >
                      <div className="premium-ket d-flex justify-content-between">
                        <div className="premium-id">#{selectedPlan.id}</div>
                        <div className="premium-expiry">
                          Duration: {selectedPlan.duration} Days
                        </div>
                      </div>
                      <div className={`premium-type selected subscription-${selectedPlan.category.toLowerCase()}`}> {/* Tambahkan kelas kategori */}
                        <h4>{selectedPlan.name}</h4>
                        <p>{selectedPlan.description}</p>
                      </div>
                    </div>
                  </div>
                )}

                {selectedPlan && plans.length > 1 && <hr className="my-4" />}

                {/* Render Another Plans */}
                {plans.length > 0 && (
                  <div>
                    <h5 className="text-muted mb-4">#Pilihan Plan lain</h5>
                    {plans
                      .filter((plan) => plan.id !== selectedPlan?.id) // Hanya render plan yang tidak dipilih
                      .map((plan) => (
                        <div
                          key={plan.id}
                          className={`premium-card mb-4 unselected`}
                          onClick={() => handlePlanSelect(plan)}
                        >
                          <div className="premium-ket d-flex justify-content-between">
                            <div className="premium-id">#{plan.id}</div>
                            <div className="premium-expiry">
                              Duration: {plan.duration} Days
                            </div>
                          </div>
                          <div className={`premium-type unselected`}>
                            <h4>{plan.name}</h4>
                            <p>{plan.description}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>

              {/* Right Section: Summary, Payment, and Payment Method */}
              <div className="col-md-5">
                <h3 className="mb-3 text-center">Detail Order</h3>
                {user && (
                  <div className="user-info mb-4">
                    <h4>{user.name}</h4>
                    <p>{user.email}</p>
                  </div>
                )}
                <div className="summary-card p-4 mb-4 text-center">
                  <div className="text-start">
                    <div className="d-flex justify-content-between align-items-end mb-4">
                      <div className="">
                        <p className="name-plan">
                          {selectedPlan ? `${selectedPlan.name}` : "Loading"}
                        </p>
                        <p className="id-plan">
                          #{selectedPlan ? `${selectedPlan.id}` : "Loading"}
                        </p>
                      </div>
                      <h4 className="price">
                        Rp.{selectedPlan ? selectedPlan.price : "Loading"}
                        <span>
                          /
                          {selectedPlan
                            ? selectedPlan.duration + " Days"
                            : "Loading"}
                        </span>
                      </h4>
                    </div>
                  </div>

                  <hr />
                  <div className="payment-method">
                    <h5 className="mb-3 text-start">Pilih Metode Pembayaran</h5>
                    <div className="payment-method-list">
                      {paymentChannels.map((channel) => (
                        <div
                          key={channel.code}
                          className={`payment-method-card ${selectedPaymentMethod === channel.code ? "selected" : ""}`}
                          onClick={() => handlePaymentMethodSelect(channel.code)} // Simpan metode pembayaran yang dipilih
                        >
                          <img
                            src={channel.icon_url}
                            alt={channel.name}
                            className="payment-logo"
                          />
                          <div className="separator"></div>
                          <div className="text-start ml10">
                            <h5>{channel.name} Payment</h5>
                          </div>
                        </div>
                      ))}
                    </div>

                  </div>
                  <div className="discount-section mt-3">
                    <h5 
                      className="mb-3 text-start" 
                      style={{ cursor: "pointer" }} 
                      onClick={() => setShowDiscountForm(prevState => !prevState)} // Toggle form diskon secara manual
                    >
                      Punya Kode Diskon?
                    </h5>
                    
                    {showDiscountForm && ( // Menampilkan form diskon jika state true
                      <form className="row form-dark" id="form_discount" method="post" name="form_discount">
                        <input
                          className="form-control"
                          id="discount_code_input"
                          name="discount_code"
                          placeholder="Masukkan kode diskon"
                          type="text"
                          value={discountCode}
                          onChange={(e) => setDiscountCode(e.target.value)}
                        />
                        <a id="btn-check-discount" onClick={handleCheckDiscount}>
                          Apply
                        </a>
                        <div className="clearfix"></div>

                        {isDiscountValid && (
                          <div className="mt-2">
                            <p className="text-succ">Diskon berhasil diterapkan: Rp {discountAmount.toLocaleString("id-ID")}</p>
                          </div>
                        )}
                      </form>
                    )}
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between align-items-end">
                    <h5 className="name-plan">Total</h5>
                    <h4 className="price">Rp.{calculateTotalPrice()}</h4>
                  </div>
                  <hr />
                  <button
                    className="btn-main w-50 mx-auto mt-1 mb-1"
                    onClick={handlePayNow}
                    disabled={isSubmitting} // Disable button saat proses transaksi
                  >
                    {isSubmitting ? "Processing..." : "Bayar Sekarang"}
                  </button>
                  <hr />
                  <div className="ketentuan text-start mt-1">
                    <p>Dengan checkout, Anda setuju dengan Ketentuan Penggunaan kami dan mengonfirmasi bahwa Anda telah menerima Kebijakan Privasi kami.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>

      <Toaster /> {/* Komponen untuk menampilkan toast */}
    </>
  );
}
