import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  // Fungsi untuk mengarahkan ke landing page dan scroll ke section yang diinginkan
  const handleNavigation = (section) => {
    navigate(`/#${section}`);
  };

  return (
    <footer>
      <div className="container">
        <div className="row gx-5">
          <div className="col-lg-6">
            <img src="/img/logo.png" alt="" />
            <div className="spacer-20"></div>
            <p>
              All-in-One platform to access over 50 premium applications, from entertainment to productivity, all with one affordable subscription.
            </p>
          </div>
          <div className="col-lg-3">
            <div className="row">
              <div className="col-lg-6 col-sm-6">
                <div className="widget">
                  <h5>Shortcut</h5>
                  <ul>
                    <li>
                      <RouterLink to="/">Home</RouterLink>
                    </li>
                    <li>
                      <a
                        onClick={() => handleNavigation("about")}
                        style={{ cursor: "pointer" }}
                      >
                        About
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => handleNavigation("pricing")}
                        style={{ cursor: "pointer" }}
                      >
                        Price
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => handleNavigation("listapps")}
                        style={{ cursor: "pointer" }}
                      >
                        List Apps
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => handleNavigation("faq")}
                        style={{ cursor: "pointer" }}
                      >
                        FAQ
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="widget">
                  <h5>Tools</h5>
                  <ul>
                    <li>
                      <RouterLink to="/dashboard">Dashboard</RouterLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="widget">
              <div className="widget">
                <h5>Follow Us on</h5>
                <div className="social-icons">
                  <RouterLink to="https://short.primefussion.com/instagram" target="_blank" rel="noopener noreferrer">
                    <i className="fa-brands fa-instagram"></i>
                  </RouterLink>
                  <RouterLink to="https://short.primefussion.com/tiktok" target="_blank" rel="noopener noreferrer">
                    <i className="fa-brands fa-tiktok"></i>
                  </RouterLink>
                  <RouterLink to="https://short.primefussion.com/x" target="_blank" rel="noopener noreferrer">
                    <i className="fa-brands fa-x-twitter"></i>
                  </RouterLink>
                  <RouterLink to="https://short.primefussion.com/youtube" target="_blank" rel="noopener noreferrer">
                    <i className="fa-brands fa-youtube"></i>
                  </RouterLink>
                  <RouterLink to="https://short.primefussion.com/discord" target="_blank" rel="noopener noreferrer">
                    <i className="fa-brands fa-discord"></i>
                  </RouterLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="subfooter">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-6">
            © {new Date().getFullYear()} PrimeFussion. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
